<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <v-container
          style="
            padding: 0 0 20px 0;
            margin: auto;
            border-radius: 5px;
            background: white;
          "
        >
          <v-card
            tile
            flat
            style="
              border-bottom: 2px solid rgba(0, 0, 0, 0.1);
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              position: sticky;
              top: 60px;
              background: white;
              z-index: 5;
            "
          >
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
              "
            >
              <v-toolbar-title class="overline">
                <p
                  :style="`font-size:${
                    wWidth > 780 ? '20px' : '3vw'
                  }; margin: 0;`"
                >
                  TAMBAHAN KOMPONEN PAYROLL
                </p>
              </v-toolbar-title>
            </div>
            <div>
              <v-btn
                type="button"
                rounded
                outlined
                elevation="0"
                color="black"
                class="black--text font-weight-bold ma-2"
                style="font-size: 12px"
                @click="close"
              >
                <v-icon small>mdi-chevron-left</v-icon>
                Kembali
              </v-btn>
            </div>
          </v-card>

          <v-card elevation="0">
            <v-form
              v-if="form !== null"
              ref="entryForm"
              @submit.prevent="submitData()"
              lazy-validation
              :disabled="loading"
            >
              <v-row style="padding: 15px" no-gutters>
                <v-col cols="12" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Judul *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-text-field
                        v-model="form.name"
                        :rules="rules.name"
                        dense
                        outlined
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Perusahaan *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        v-model="form.company"
                        :items="dropdown.company"
                        :rules="rules.company"
                        :disabled="isDisableCompanyDropdown"
                        clearable
                        item-text="name"
                        item-value="id"
                        return-object
                        outlined
                        dense
                        style="margin: 0"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col
                  v-if="form.payroll_component_id !== 10"
                  cols="4"
                  style="padding: 0"
                >
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Department
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-select
                        label="Department"
                        v-model="form.department"
                        :items="dropdown.department"
                        :disabled="form.company === null"
                        item-text="name"
                        item-value="id"
                        return-object
                        outlined
                        dense
                        clearable
                        style="margin: 0"
                      />
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Template *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        v-model="form.template"
                        :items="dropdown.template"
                        :rules="rules.template"
                        :disabled="form.company === null"
                        clearable
                        item-text="name"
                        item-value="id"
                        return-object
                        outlined
                        dense
                        style="margin: 0"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>

                <v-col
                  v-if="form.template != null"
                  cols="12"
                  style="padding: 0"
                >
                  <div style="padding: 0 10px; height: 50px">
                    <div
                      :style="`
                        margin: 0;
                        font-size: 12px; position:relative; top:-22px; z-index:2;`"
                    >
                      <span> Jabatan yang akan digenerate: </span>
                      <br />
                      <v-autocomplete
                        v-model="form.template.employee_level_name"
                        :items="form.template.employee_level_name"
                        chips
                        multiple
                        readonly
                        small-chips
                        append-icon=""
                        style="margin: 0; position: relative; top: -15px"
                      />
                    </div>
                  </div>
                </v-col>
                <v-col cols="4" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Komponen *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        v-model="form.payroll_component_id"
                        :rules="rules.component"
                        :items="dropdown.payrollComponent"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        style="margin: 0"
                      ></v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col
                  v-if="form.payroll_component_id === 13"
                  cols="12"
                  style="padding: 0"
                >
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Karyawan *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <v-autocomplete
                        v-model="form.employee_id"
                        :rules="rules.employee"
                        :items="dropdown.employee"
                        :search-input.sync="employeeSearch"
                        item-text="name"
                        item-value="id"
                        return-id
                        outlined
                        dense
                        hide-details
                        hide-no-data
                        style="margin: 0; height: 55px"
                        clearable
                      >
                        <template v-slot:item="data">
                          <template>
                            <v-list-item-avatar>
                              <img :src="`${data.item.photo}`" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ data.item.name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ data.item.company_name }}-{{ data.item.nik }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="6" style="padding: 0">
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Periode *
                      </p>
                    </v-col>
                    <v-row no-gutters>
                      <v-col cols="6" style="padding: 0">
                        <v-text-field
                          v-model="form.period_start"
                          :rules="rules.period_start"
                          type="month"
                          name="monthperiod"
                          step="1"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col
                        v-if="form.payroll_component_id === 13"
                        class="d-flex justify-center align-center"
                        cols="1"
                        style="padding: 0"
                      >
                        <p style="padding: 0; margin-bottom: 30px">s/d</p>
                      </v-col>
                      <v-col
                        v-if="form.payroll_component_id === 13"
                        cols="5"
                        style="padding: 0 7px 0 0"
                      >
                        <v-text-field
                          v-model="form.period_end"
                          :rules="rules.period_end"
                          type="month"
                          name="monthperiod"
                          step="1"
                          outlined
                          dense
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col
                  v-if="form.payroll_component_id === 13"
                  cols="6"
                  style="padding: 0"
                >
                  <div style="padding: 0 10px">
                    <v-col cols="12" style="padding: 0">
                      <p
                        style="
                          margin: 0;
                          font-size: 12px;
                          font-weight: bold;
                          color: rgba(0, 0, 0, 0.5);
                        "
                      >
                        Nominal premi tahunan *
                      </p>
                    </v-col>
                    <v-col cols="12" style="padding: 0">
                      <input
                        v-model="form.premi_insurance_yearly"
                        type="text"
                        class="delimiter"
                      />
                      <!-- <v-text-field
                  v-model="form.premi_insurance_yearly"
                  type="number"
                  min="0"
                  dense
                  outlined
                /> -->
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" style="padding: 0 10px; margin-top: 10px">
                  <div
                    v-if="permission.create_perm == 1"
                    style="margin-right: 15px; height: 45px"
                    class="d-flex justify-end align-center"
                  >
                    <v-btn
                      style="font-size: 12px"
                      type="submit"
                      elevation="2"
                      tilerounded="5px"
                      color="primary"
                      class="white--text"
                    >
                      SUBMIT
                    </v-btn>
                    <v-btn
                      style="font-size: 12px"
                      @click="clearForm"
                      type="button"
                      elevation="2"
                      tilerounded="5px"
                      color="pink"
                      class="white--text ml-5"
                    >
                      Reset Form
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <!-- <v-row style="padding: 15px" no-gutters>
          <v-col cols="12" style="padding: 0 10px; margin-top: 10px">
            <v-card>
              <v-data-table
                mobile-breakpoint="0"
                :headers="headers"
                style="cursor: pointer"
                :items="componentItems"
                @click:row="editItem"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                hide-default-footer
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div
                    style="
                      font-size: 14px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="editItem(item)"
                        small
                        color="primary"
                        class="mr-2"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        color="error"
                        @click.stop="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
                <template v-slot:footer>
                  <v-toolbar>
                    <v-btn outlined color="primary" small>
                      <v-icon left small> mdi-plus </v-icon>
                      Tambah baris
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row> -->
            </v-form>
          </v-card>
          <div
            v-if="loading"
            class="d-flex justify-center align-center"
            style="
              position: absolute;
              top: 0;
              z-index: 6;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgba(255, 255, 255, 0.5);
            "
          >
            <v-progress-circular
              :size="100"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-container>
      </div>
    </div>
    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      DATA NOT FOUND
    </v-card>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import buildType from '../../../../services/buildType'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'leavesubmission',
  components: {},
  data() {
    return {
      config: require('../../../../services/config.json')[env],
      hrsApi: buildType.apiURL('hrs'),
      eSanqua: buildType.apiURL('esanqua'),
      oAuthApi: buildType.apiURL('oauth'),
      build: process.env.VUE_APP_BUILD_TYPE,
      permission: null,
      appLevel: null,
      userLevel: null,
      isLoadingPage: true,
      isDisableCompanyDropdown: false,
      loading: false,
      rDialog: false,
      search: null,
      componentItems: [],
      paramAPI: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        itemsPerPage: 10,
        sortBy: 'id',
        sortType: 'ASC',
        company_id: null,
        department_id: null,
        employee_id: null
      },
      form: {
        name: '',
        period_start: '',
        period_end: null,
        payroll_component_id: null,
        company: null,
        department: null,
        premi_insurance_yearly: '0',
        employee_id: null,
        template: null
      },
      wWidth: window.innerWidth,
      rules: {
        name: [],
        period_start: [],
        period_end: [],
        company: [],
        component: [],
        employee: [],
        template: []
      },
      dropdown: {
        company: [],
        department: [],
        component: [],
        employee: [],
        template: [],
        payrollComponent: [
          // {
          //   id: 10,
          //   name: 'THR'
          // },
          // {
          //   id: 11,
          //   name: 'Bonus'
          // },
          // {
          //   id: 12,
          //   name: 'MCU'
          // },
          // {
          //   id: 13,
          //   name: 'Asuransi'
          // }
        ]
      },

      headers: [
        {
          text: 'NIK',
          value: 'employee.nik',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nama karyawan',
          value: 'employee.name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nominal',
          value: 'amount',
          align: 'left',
          sortable: false
        },
        {
          text: 'Periode',
          value: 'period',
          align: 'left',
          sortable: false
        },
        {
          text: 'Action',
          value: 'action',
          align: 'left',
          sortable: false
        }
      ],
      employeeSearch: null,
      employeeSelect: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from
    })
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getLoginPayroll'])
  },
  async mounted() {
    // this.startDelimiter()
    // this.form.name = this.getUserProfile.employee.name
    // this.form.company_id = Number(this.getUserProfile.employee.company.plant_id)
    // this.form.department_id = this.getUserProfile.employee.department_id
    // this.form.position_id = this.getUserProfile.employee.level_id
    // this.getDetailEmployee(this.getUserProfile.employee_id)

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.appLevel = this.config.application.hr.userLevel
    // this.appLevel.administrator =
    //   this.config.application.all.userLevel.administrator
    if (this.getLoginPayroll) {
      this.verifyAccessToken()
    } else {
      setTimeout(() => {
        this.$router.push('/hr/payroll/additional')
      }, 100)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    console.log('entry>>', this.$refs.entryForm)
    if (this.$refs.entryForm.reset() !== undefined) {
      this.$refs.entryForm.reset()
    }
  },
  watch: {
    employeeSearch(val) {
      if (val !== null) {
        if (val !== '') {
          val && val !== this.employeeSelect && this.querySelections(val)
        } else {
          this.employeeSelect = null
          this.dropdown.employee = []
        }
      } else {
        this.employeeSelect = null
        this.dropdown.employee = []
      }
    },

    'form.premi_insurance_yearly'(val) {
      if (this.form !== null) {
        var removeChar = this.form.premi_insurance_yearly.replace(/[^0-9]/g, '') // This is to remove alphabets and special characters.
        // console.log(removeChar)
        var removeDot = removeChar.replace(/\./g, '') // This is to remove "DOT"
        this.form.premi_insurance_yearly = removeDot

        var formatedNumber = this.form.premi_insurance_yearly.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ','
        )
        this.form.premi_insurance_yearly = formatedNumber
      }
    },
    'form.company'(val) {
      this.dropdownDepartment()
      if (this.form != null) {
        if (val != null) {
          this.dropdownTemplate()
          return null
        } else {
          this.dropdown.template = []
        }
      }
    },
    'form.template'(val) {
      if (this.form != null) {
        if (val != null) {
          console.log(val)
          if (val.payroll_component_id != null) {
            this.dropdownComponent()
            return null
          }
        }
      }
      this.dropdown.payrollComponent = []
    }
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),
    ...mapMutations(['setLoginPayroll']),
    async verifyAccessToken() {
      this.loading = true
      await axios
        .post(
          `${this.oAuthApi}otp/access_token/verify`,
          {},
          {
            headers: {
              'x-access-token': this.getLoginPayroll
            }
          }
        )
        .then((res) => {
          console.log('verify access token>>>', res)
          if (res.data.status_code === '00') {
            this.getDataMenu()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
            this.setLoginPayroll(null)
          }

          this.loading = false
        })
        .catch((err) => {
          console.log('error>>', err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      const split = this.$route.path.split('/')
      const routeModule = '/' + split[1] + '/' + split[2]
      await this.getMenuId({
        keyword: this.paramAPI.keyword,
        path: routeModule
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId
              // ||
              // application.id === this.config.application.all.applicationId
            )
            if (this.userLevel !== null) {
              this.checkMenuAccess([menuId, this.userLevel.id])
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    initDataForLayout() {
      if (this.userLevel.is_support) {
        this.paramAPI.company_id = 0
      } else {
        this.paramAPI.company_id = Number(
          this.getUserProfile.employee.company.plant_id
        )
      }
      this.getDropdownCompany()
    },
    getDropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    async dropdownDepartment() {
      let url = `${this.hrsApi}master/universal/department/dropdown`
      if (this.form.company !== null) {
        url = url + `?filter=[{"company_id":${this.form.company.id}}]`
      }
      await axios
        .get(url)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.department = res.data.data)
          }
          return (this.dropdown.department = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.department = [])
        })
    },
    async dropdownTemplate() {
      let url = `${this.hrsApi}master/universal/payrolltemplate/dropdown?source=payroll`
      if (this.form.company !== null) {
        url = url + `&company_id=${this.form.company.id}`
      }
      await axios
        .get(url)
        .then((res) => {
          if (res.data.status_code === '00') {
            return (this.dropdown.template = res.data.data)
          }
          return (this.dropdown.template = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.template = [])
        })
    },
    async dropdownComponent() {
      let url = `${this.hrsApi}master/universal/payrollcomponent/dropdown`
      if (this.form.template !== null) {
        url = url + `?arr_id=${this.form.template.payroll_component_id}`
      }
      await axios
        .get(url)
        .then((res) => {
          console.log('component>>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdown.payrollComponent = res.data.data)
          }
          return (this.dropdown.payrollComponent = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdown.template = [])
        })
    },
    submitData() {
      const self = this
      this.rules.name = [(v) => !!v || 'Name is required']
      this.rules.component = [(v) => !!v || 'Component is required']
      this.rules.company = [(v) => !!v || 'Company is required']
      this.rules.period_start = [(v) => !!v || 'Period Start is required']
      this.rules.template = [(v) => !!v || 'Template is required']

      if (this.form.payroll_component_id === 13) {
        this.rules.employee = [(v) => !!v || 'employee is required']
        this.rules.period_end = [(v) => !!v || 'Period End is required']
      }

      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      let xFlag = true
      const form = {
        act: 'add',
        name: this.form.name,
        period_start_month:
          this.form.period_start !== null
            ? Number(this.form.period_start.split('-')[1])
            : null,
        period_start_year:
          this.form.period_start !== null
            ? this.form.period_start.split('-')[0]
            : null,
        period_end_month:
          this.form.period_end !== null
            ? Number(this.form.period_end.split('-')[1])
            : null,
        period_end_year:
          this.form.period_end !== null
            ? this.form.period_end.split('-')[0]
            : null,
        payroll_component_id: this.form.payroll_component_id
      }
      if (form.payroll_component_id !== 13) {
        form.period_end_month = form.period_start_month
        form.period_end_year = form.period_start_year
      }

      if (this.form.company !== null) {
        Object.assign(form, {
          company_id: this.form.company.id,
          company_name: this.form.company.name,
          template_id: this.form.template.id,
          employee_level_id: this.form.template.employee_level_id
        })
      }
      if (this.form.department !== null) {
        Object.assign(form, {
          department_id: this.form.department.id,
          department_name: this.form.department.name
        })
      }

      if (this.form.payroll_component_id === 13) {
        Object.assign(form, {
          premi_insurance_yearly: this.form.premi_insurance_yearly,
          employee_id: this.form.employee_id
        })
        form.premi_insurance_yearly = Number(
          form.premi_insurance_yearly.replace(/,/g, '')
        )
      }

      if (form.act === 'update') {
        Object.assign(form, {
          id: this.$route.params.id
        })
      }

      if (form.payroll_component_id === 13) {
        if (form.period_end_year !== form.period_start_year) {
          xFlag = false
          this.showMsgDialog(
            'warning',
            'tahun periode akhir tidak boleh melebihi atau kurang dari tahun periode awal',
            false
          )
        }
      }
      if (xFlag) {
        // console.log('Formulir>>>>', form)
        this.save(form)
      }
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.hrsApi}employee/payroll_additional/save`, form)
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.clearForm()
            this.showMsgDialog('success', res.data.status_msg, false).then(
              (response) => {
                setTimeout(() => {
                  this.$router.push(
                    `/hr/payroll/additional/detail/${
                      form.act === 'add' ? res.data.created_id : form.id
                    }`
                  )
                }, 500)
              }
            )
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          return null
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
          return null
        })
    },
    addItem() {
      setTimeout(() => {
        this.rDialog = true
      }, 100)
    },
    // editItem(pItem) {},
    deleteItem(pitem) {
      this.componentItems.pop()
    },
    saveItem() {
      const form = {
        id: Math.random() * 100,
        employee: this.form.component.employee,
        current_amount: this.form.component.current_amount,
        new_amount: this.form.component.new_amount
      }
      // if (form !== null) {
      this.componentItems.push(form)
      setTimeout(() => {
        // this.showMsgDialog('success', 'Save Success', false)
        this.rDialog = false
      }, 300)
      // }
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    querySelections(v) {
      let url = `${this.hrsApi}employee/dropdown?keyword=${v}`
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      // if (this.detail.department_id !== null) {
      //   url = url + `&department_id=${this.detail.department_id}`
      // }
      axios
        .get(url)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: res.data.data[i].id,
                nik: res.data.data[i].nik,
                name: res.data.data[i].name,
                company_id: res.data.data[i].company_id,
                company_name: this.getCompanyAlias(res.data.data[i].company_id),
                photo: res.data.data[i].photo
              })
            }
            return (this.dropdown.employee = resArr)
          }
          return (this.dropdown.employee = [])
        })
        .catch((err) => {
          this.dropdown.employee = []
          return console.log(err)
        })
    },
    clearForm(val) {
      // reset error validation
      // this.$refs.entryForm.resetValidation()

      // // reset form
      this.$refs.entryForm.reset()

      this.rules.name = []
      this.rules.component = []
      this.rules.company = []
      this.rules.period_start = []
      this.rules.period_end = []
      this.rules.employee = []
    },
    close() {
      this.$refs.entryForm.reset()
      // if (this.prevRoute !== undefined) {
      //   this.$router.push(this.prevRoute.path)
      // } else {
      this.$router.push('/hr/payroll/additional')
      // }
    },

    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleDateString()
        return local
      }
    },

    getCompanyAlias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJ2'
      }
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    }
    // startDelimiter() {

    // }
  }
}
</script>
<style lang="scss" scoped>
.delimiter {
  width: 100%;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 8px 10px;
  border: 1px solid #949494;
  border-radius: 5px;
  outline: none;
  text-align: left;
}
.delimiter:focus {
  border: 1px solid #000000;
}
.delimiter:hover {
  border: 1px solid #000000;
}
</style>
